<template>
  <div>
    <div
      class="d-flex flex-column p-6 bgi-size-cover bgi-no-repeat rounded bg-success"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-black">Perjanjian terdaftar hari ini</span>
        <span
          class="btn btn-text btn-danger btn-sm font-weight-bold btn-font-md ml-2"
        >
          {{new_appointments}}
        </span>
      </h4>
    </div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
export default {
  name: "WMMedicineNotification",
  props:["new_appointments"],
};
</script>
