<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <b-alert
                v-if="
                  tenantConfig.is_subscribe == 0 && tenantConfig.diff_date > 0
                "
                show
                style="
                  background-color: #245590;
                  border: none;
                  border-radius: 30px;
                "
                >Anda sedang dalam masa coba gratis dan tersisa
                {{ tenantConfig.diff_date }} hari,
                <u @click="$router.push('/subscribe/package')"
                  >Langganan sekarang</u
                ></b-alert
              >
              <b-alert
                v-if="
                  tenantConfig.is_subscribe == 1 &&
                  tenantConfig.diff_date < 8 &&
                  tenantConfig.diff_date >= 1
                "
                show
                style="
                  background-color: #245590;
                  border: none;
                  border-radius: 30px;
                "
                >Masa berlangganan anda tersisa
                {{ tenantConfig.diff_date }} hari,
                <u @click="$router.push('/subscribe/package')"
                  >Langganan sekarang</u
                ></b-alert
              >
              <b-alert
                v-if="
                  tenantConfig.is_subscribe == 1 && tenantConfig.diff_date < 1
                "
                show
                style="
                  background-color: #245590;
                  border: none;
                  border-radius: 30px;
                "
                >Masa berlangganan anda telah habis, silahkan lankukan
                perpanjangan.
                <u @click="$router.push('/subscribe/package')"
                  >Perpanjang sekarang</u
                ></b-alert
              >
              <b-alert
                v-if="
                  tenantConfig.is_subscribe == 0 && tenantConfig.diff_date < 1
                "
                show
                style="
                  background-color: #245590;
                  border: none;
                  border-radius: 30px;
                "
                >Masa coba gratis anda telah habis, silahkan
                <u @click="$router.push('/subscribe/package')"
                  >Langganan sekarang</u
                ></b-alert
              >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <!-- <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar> -->
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
// import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

import module from "@/core/modules/CrudModule.js";
import {
  setConfiguration,
  getConfiguration,
} from "@/core/services/jwt.service.js";

export default {
  name: "Layout",

  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    // KTStickyToolbar,
    KTScrollTop,
    Loader,
  },

  data() {
    return {
      tenantConfig: {},
    };
  },

  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },

  mounted() {
    this.setTenantConfig();
    // update subscrie data
    this.$root.$on("subUpdate", () => {
      this.setTenantConfig();
      // this.tenantConfig = value;
    });

    // this.getDbConfig()
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // if (getConfiguration() == null) {
    //   this.getSetting()
    // }
    this.removeLoader();

    // Simulate the delay page loading
    // setTimeout(() => {
    //   // Remove page loader after some time
    //   this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    // }, 3000);
  },

  methods: {
    setTenantConfig() {
      this.tenantConfig = JSON.parse(localStorage.getItem("tenantconfig"));
    },

    async getDbConfig() {
      let response = await module.getDbConfig(
        "get-db-config?domain=hayandra.medhop.id"
      );
      // If Data Found
      if (response != null) {
        window.localStorage.setItem(
          "dbconfiguration",
          JSON.stringify(response)
        );
        window.dbconfiguration = response;
      }
    },
    async getSetting(dbconfiguration) {
      console.log("get settings dari layout");
      let response = await module.get(
        `settings-no-auth/1?database_name=${dbconfiguration.database_name}&database_password=${dbconfiguration.database_password}&database_username=${dbconfiguration.database_username}`
      );
      // If Data Found
      if (response != null) {
        setConfiguration(JSON.stringify(response));
      }
    },
    removeLoader() {
      setTimeout(() => {
        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);
    },
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      console.log("loader", !/false/.test(this.layoutConfig("loader.type")));
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>


<style>
.btn-green {
  background-color: #43a047;
  border-color: #43a047;
}

.btn-outline-green {
  color: #43a047;
  background-color: transparent;
  border-color: #43a047;
}

.btn-outline-green:hover {
  color: white;
  background-color: #43a047;
  border-color: #43a047;
}

.btn-outline-green:hover i {
  color: white;
}

.btn-outline-green i {
  color: #43a047;
}

.text-green {
  color: #43a047;
}
</style>