<template>
  <div>
    <div
      class="d-flex flex-column p-6 bgi-size-cover bgi-no-repeat rounded bg-warning"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-black">Pendonor terdaftar hari ini</span>
        <span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >
          0
        </span>
      </h4>
    </div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
export default {
  name: "WMDonorNotification",
};
</script>
