<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <img alt="Logo" :src="img" height="300"/>
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
import { setConfiguration, getConfiguration } from "@/core/services/jwt.service.js";

export default {
  name: "Loader",
  props: {
    logo: String,
    spinnerClass: String
  },

  data(){
    return{
      img:"/images/iona-clinic.png",
    }
  },

  methods:{
    getSettings(){
      let data = getConfiguration() != null ? getConfiguration() : ''  
      this.img = 'https://is3.cloudhost.id/medhop-prod-bucket/tenant-iona-demo/settings/IONA/logo/2022/07/1657184854____Logo Transparant-01.png'
    }
  },

  mounted(){
    this.getSettings();
  }
};




</script>
